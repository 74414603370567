import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css'; // We'll create this CSS file for styling
import logo from './icon.jpeg'; // Tell webpack this JS file uses this image.


const App = () => {
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [inValue, setInValue] = useState('');
  const [timestamp, setTimestamp] = useState(new Date().toISOString().slice(0, 16));
  const [description, setDescription] = useState('');
  const [duration, setDuration] = useState('');
  const [calls, setCalls] = useState('');
  const [email, setEmail] = useState('');
  const [csvContent, setCsvContent] = useState('');
  const [isCsvFetched, setIsCsvFetched] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState('logCall');
  const [tableHeaders, setTableHeaders] = useState([]);
  const [darkMode, setDarkMode] = useState(false);
	const [menuVisible, setMenuVisible] = useState(false); // Control menu visibility
  const [salary, setSalary] = useState(localStorage.getItem('salary') || '');
  const [calculatedPay, setCalculatedPay] = useState(0);
  

  // Load theme from localStorage
  useEffect(() => {
    const storedTheme = localStorage.getItem('darkMode');
    if (storedTheme === 'true') {
      setDarkMode(true);
    }
  }, []);

  useEffect(() => {
    const authStatus = localStorage.getItem('isAuthenticated');
    const storedPassword = localStorage.getItem('password');
    if (authStatus === 'true') {
      setIsAuthenticated(true);
      setPassword(storedPassword || '');
      calculatePay();
    }
  }, []);

  // Save theme to localStorage and change className based on the mode
  useEffect(() => {
    localStorage.setItem('darkMode', darkMode);
    document.body.className = darkMode ? 'dark' : 'light';
  }, [darkMode]);

  useEffect(() => {
    localStorage.setItem('isAuthenticated', isAuthenticated);
    if (isAuthenticated) {
      localStorage.setItem('password', password);
    } else {
      localStorage.removeItem('password');
    }
  }, [isAuthenticated, password]);

  const saveSalary = () => {
    localStorage.setItem('salary', salary);
    alert('Salary saved successfully!');
  };
  
  const calculatePay = async () => {
    try {
      const response = await axios.get('/api/getcalls', { 
        params: { password },
        headers: { 'Content-Type': 'application/json' }
      });
      
      const calls = response.data.calls;
  
      const hourlyRate = salary / (52 * 35);
      let totalPay = 0;
      
      totalPay += 16 * 5 * 3.25; // Fixed weekday amount
      totalPay += 24 * 2 * 6.50; // Fixed weekend amount

      calls.forEach(call => {
        const callDate = new Date(call.timestamp);
        const dayOfWeek = callDate.getDay();
        const duration = parseFloat(call.duration);

        if (dayOfWeek >= 1 && dayOfWeek <= 5) {
          // Weekday
          if (hourlyRate < 14.10 ) {
            totalPay += duration * 14.10;
          } else {
            totalPay += duration * hourlyRate;
          }
        } else {
          // Weekend
          if (hourlyRate < 22.45 ) {
            totalPay += duration * 22.45;
          } else {
            totalPay += duration * hourlyRate;
          }
        }
      });

      totalPay = totalPay * 0.6 ; // 60% for after tax

      setCalculatedPay(totalPay.toFixed(2));
      console.log(totalPay);
    } catch (error) {
      console.error('Error calculating pay:', error);
      alert('Error calculating pay. Please try again.');
    }
  };
  

  const fetchRawCSV = async () => {
    try {
      const response = await axios.post('/api/rawcsv', { password });
      setCsvContent(response.data.content);
      setIsCsvFetched(true);
    } catch (error) {
      console.error('Error fetching raw CSV:', error);
    }
  };

  const saveEditedCSV = async () => {
    try {
      await axios.post('/api/savecsv', { password, csvContent });
      alert('CSV updated successfully');
    } catch (error) {
      console.error('Error saving CSV:', error);
    }
  };

  const exportAsJson = async () => {
    try {
      const response = await axios.get('/api/export/json', { responseType: 'blob' });
      const blob = new Blob([response.data], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'callLogs.json';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error exporting as JSON:', error);
    }
  };

  const exportAsCsv = async () => {
    try {
      const response = await axios.get('/api/export/csv', { responseType: 'blob' });
      const blob = new Blob([response.data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'callLogs.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error exporting as CSV:', error);
    }
  };

  const emailLogs = async () => {
    try {
      const response = await axios.post('/api/email', { email });
      alert(response.data.message);
    } catch (error) {
      console.error('Error emailing logs:', error);
    }
  };

  const handleLogin = async () => {
    try {
      const response = await axios.post('/api/login', { password });
      setIsAuthenticated(true);
      localStorage.setItem('password', password); // Store password when logging in
      alert(response.data.message);
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  const handleLogCall = async () => {
    try {
      const response = await axios.post('/api/logcall', {
        password,
        inValue,
        timestamp,
        description,
        duration,
      });
      alert(response.data.message);
      setInValue('');
      setTimestamp(new Date().toISOString().slice(0, 16));
      setDescription('');
      setDuration('');
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  // const handleShowCalls = async () => {
  //   try {
  //     const response = await axios.post('/api/showcall', { password });
  //     const csvData = response.data.calls;

  //     const rows = csvData.split('\n').filter(row => row.trim() !== '');
  //     const headers = ['inValue', 'timestamp', 'description', 'duration'];
  //     setTableHeaders(headers);

  //     const processedCalls = rows.map(row => {
  //       const columns = row.split(',').map(col => col.trim());
  //       return headers.reduce((obj, header, index) => {
  //         obj[header] = columns[index] || '';
  //         return obj;
  //       }, {});
  //     });

  //     setCalls(processedCalls);
  //   } catch (error) {
  //     alert(error.response.data.message);
  //   }
  // };
  const handleShowCalls = async () => {
    try {
      const response = await axios.post('/api/showcall', { password });
      const csvData = response.data.calls;
  
      const rows = csvData.split('\n').filter(row => row.trim() !== '');
      const headers = rows[0].split(',').map(header => header.trim());
      setTableHeaders(headers);
  
      const processedCalls = rows.slice(1).map(row => {
        const columns = row.split(',').map(col => col.trim());
        return headers.reduce((obj, header, index) => {
          obj[header] = columns[index] || '';
          return obj;
        }, {});
      });
  
      setCalls(processedCalls);
    } catch (error) {
      alert(error.response.data.message);
    }
  };
  

  const renderCallsTable = () => {
    if (calls.length === 0) {
      return <p>No calls to display.</p>;
    }

    return (
      <table>
        <thead>
          <tr>
            {tableHeaders.map(header => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {calls.map((call, index) => (
            <tr key={index}>
              {tableHeaders.map(header => (
                <td key={`${index}-${header}`}>{call[header]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('password'); // Remove password from localStorage on logout
  };

  const renderSelectedMenu = () => {
    switch (selectedMenu) {
      case 'logCall':
        return (
          <div className="form-container">
            <h2>Log Call</h2>
            <div className="form-field">
              <label>In Value</label>
              <input
                type="text"
                value={inValue}
                onChange={(e) => setInValue(e.target.value)}
                placeholder="Enter In Value"
              />
            </div>
            <div className="form-field">
              <label>Timestamp</label>
              <input
                type="datetime-local"
                value={timestamp}
                onChange={(e) => setTimestamp(e.target.value)}
              />
            </div>
            <div className="form-field">
              <label>Description</label>
              <input
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter Description"
              />
            </div>
            <div className="form-field">
              <label>Duration</label>
              <input
                type="text"
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                placeholder="Enter Duration"
              />
            </div>
            <button className="submit-button" onClick={handleLogCall}>Submit</button>
          </div>
        );
      case 'showCalls':
        return (
          <div className="menu-content">
            <h2>Show Calls</h2>
            {renderCallsTable()}
          </div>
        );
      case 'exportLogs':
        return (
          <div className="menu-content">
            <h2>Export Logs</h2>
            <button onClick={exportAsJson}>Export as JSON</button>
            <button onClick={exportAsCsv}>Export as CSV</button>
          </div>
        );
      case 'emailLogs':
        return (
          <div className="menu-content">
            <h2>Email Logs</h2>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
            />
            <button onClick={emailLogs}>Email Logs</button>
          </div>
        );
      case 'csvManagement':
        return (
          <div className="menu-content">
            <h2>CSV Management</h2>
            <button onClick={fetchRawCSV}>Refresh CSV</button>
            <br />
            {isCsvFetched && (
              <>
                <textarea
                                   value={csvContent}
                                   onChange={(e) => setCsvContent(e.target.value)}
                                   rows="10"
                                   cols="50"
                                 />
                                 <br />
                                 <button onClick={saveEditedCSV}>Save Changes</button>
                               </>
              )}
          </div>
        );
        case 'storeSalary':
          return (
            <div className="menu-content">
              <h2>Store Salary</h2>
              <input
                type="number"
                value={salary}
                onChange={(e) => setSalary(e.target.value)}
                placeholder="Enter yearly salary"
              />
              <button onClick={saveSalary}>Save Salary</button>
            </div>
          );
        case 'calculatePay':
          return (
            <div className="menu-content">
              <h2>Calculate Pay</h2>
              <button onClick={calculatePay}>Calculate Pay</button>
            </div>
          );
        default:
          return null;
      }
    };
                 
  return (
    <div className={`app-container ${darkMode ? 'dark' : 'light'}`}>
      {!isAuthenticated ? (
        <div className="login-container">
          <h2>Login</h2>
          <input
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button onClick={handleLogin}>Login</button>
        </div>
      ) : (
        <div className="content-container">
          <img src={logo} alt="Logo" />
          <div className="header">
							<button className="toggle-menu-button" onClick={() => setMenuVisible(!menuVisible)}>
             <h2> {menuVisible ? 'Hide Menu' : 'Show Menu'} </h2>
            </button>
					  <button onClick={() => setSelectedMenu('logCall')}>Log Call</button>
          </div>
          {calculatedPay > 0 && (
            <h2 className="calculated-pay">Estimated Pay After 40% Tax: £{calculatedPay}</h2>
          )}

          {menuVisible && (
						<>
						<div className="theme-toggle">
              <label htmlFor="themeSwitch">Dark Mode</label>
              <input
                type="checkbox"
                id="themeSwitch"
                checked={darkMode}
                onChange={() => setDarkMode(!darkMode)}
              />
            </div>
            <div className="menu-buttons">
              <button onClick={() => {
								setSelectedMenu('logCall');
								setMenuVisible(!menuVisible);
							}}>Log Call</button>
              <button onClick={() => {
                setSelectedMenu('showCalls');
                handleShowCalls();
								setMenuVisible(!menuVisible);
              }}>Show Calls</button>
              <button onClick={() => {
								setSelectedMenu('exportLogs');
								setMenuVisible(!menuVisible);
							}}>Export Logs</button>
              <button onClick={() => {
								setSelectedMenu('emailLogs');
								setMenuVisible(!menuVisible);
							}}>Email Logs</button>
              <button onClick={() => {
                setSelectedMenu('csvManagement');
                fetchRawCSV();
								setMenuVisible(!menuVisible);
               }}>CSV Management</button>

              <button onClick={() => {
                setSelectedMenu('storeSalary');
                setMenuVisible(!menuVisible);
              }}>Store Salary</button>
              <button onClick={() => {
                setSelectedMenu('calculatePay');
                setMenuVisible(!menuVisible);
              }}>Calculate Pay</button>

              <button onClick={handleLogout}>Logout</button>
            </div>
						</>
          )}

          {renderSelectedMenu()}
        </div>
      )}
    </div>
  );
};

export default App;